import ImageResizer from "@/components/models/imageResizer.vue";
<template>
  <div>
    <!-- 图片裁剪-->
    <image-resizer v-if="uploadComplete" :imageObj="uploadImageInfo" @back="handleBack" />
    <image-upload @uploadComplete="handleUploadComplete" v-else />
  </div>
</template>
<script setup>
import ImageUpload from "./image-upload.vue";
import ImageResizer from "@/components/models/imageResizer.vue";
import { ref } from "vue";
import { eventTracking } from "@/utils/eventTracking.js";
import { useCheckResourceStore } from "@/store";
import { authenticator } from "@/utils";

const { deductResourceFn } = useCheckResourceStore();
const uploadComplete = ref(false);
const uploadImageInfo = ref({});

const handleUploadComplete = async (imageObj) => {
  eventTracking("booltool_page_upload", { tool_name: "resizer", is_batch: false })
  await deductResourceFn('IMAGE_RESIZER', false);
  await authenticator.refresh();
  uploadImageInfo.value = imageObj;
  uploadComplete.value = true;
};

const handleBack = () => {
  uploadImageInfo.value = {};
  uploadComplete.value = false;
};
</script>

<style lang="less">

</style>
