"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = ["width", "height"];
var vue_3 = require("vue");
var dom_helper_1 = require("../matting-board/helpers/dom-helper");
var drawing_helper_1 = require("../matting-board/helpers/drawing-helper");
exports.default = vue_1.defineComponent({
    __name: 'index',
    props: {
        imageData: {
            type: ImageData,
            required: true
        },
        zoomRatio: {
            type: Number,
            default: 1
        },
        targetSize: {
            type: Object,
            required: true
        },
        limitSize: {
            type: Object,
            default: function () {
                return {
                    min: 50,
                    max: 6000
                };
            }
        }
    },
    setup: function (__props, _a) {
        var expose = _a.expose;
        var props = __props;
        var boxEle = vue_3.ref(null);
        var cvsEle = vue_3.ref(null);
        var cvsCtx = vue_3.ref(null);
        var cvsSize = vue_3.ref({ width: 0, height: 0 });
        var imageSource = vue_3.ref(null);
        var imageCoverRect = vue_3.ref(null);
        var isDragging = vue_3.ref(false);
        var prevDownPoint = vue_3.ref(null);
        expose({
            getImageCanvas: getImageCanvas,
            generateCropImageUrl: generateCropImageUrl,
        });
        function getImageCanvas() {
            return cvsEle.value;
        }
        function generateCropImageUrl() {
            var targetSize = props.targetSize;
            var context2D = dom_helper_1.createContext2D({ targetSize: targetSize });
            var zoomRatio = props.zoomRatio;
            var image = imageSource.value;
            var _a = cvsSize.value, cvsWidth = _a.width, cvsHeight = _a.height;
            var coverRect = imageCoverRect.value;
            var viewRatio = targetSize.width / cvsWidth;
            var cropRect = {
                x: coverRect.tx / coverRect.sx,
                y: coverRect.ty / coverRect.sy,
                width: cvsWidth / coverRect.sx,
                height: cvsHeight / coverRect.sy
            };
            var translateX = (1 - zoomRatio) * cvsWidth / 2 * viewRatio;
            var translateY = (1 - zoomRatio) * cvsHeight / 2 * viewRatio;
            context2D.imageSmoothingEnabled = false;
            context2D.translate(translateX, translateY);
            context2D.scale(zoomRatio, zoomRatio);
            context2D.drawImage(image, cropRect.x, cropRect.y, cropRect.width, cropRect.height, 0, 0, targetSize.width, targetSize.height);
            return context2D.canvas.toDataURL("image/png");
        }
        vue_3.onMounted(function () {
            initContextsAndSize();
        });
        function initContextsAndSize() {
            var _this = this;
            cvsCtx.value = cvsEle.value.getContext('2d');
            vue_3.watchEffect((function () { return __awaiter(_this, void 0, void 0, function () {
                var boxSize, targetSize, _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!(props.imageData && props.targetSize)) return [3, 3];
                            if (props.targetSize.width > props.limitSize.max || props.targetSize.height > props.limitSize.max) {
                                return [2];
                            }
                            if (props.targetSize.width < props.limitSize.min || props.targetSize.height < props.limitSize.min) {
                                return [2];
                            }
                            boxSize = { width: boxEle.value.clientWidth, height: boxEle.value.clientHeight };
                            targetSize = { width: props.targetSize.width, height: props.targetSize.height };
                            cvsSize.value = computedValidCanvasSize(boxSize, targetSize);
                            cvsSize.value = {
                                width: cvsSize.value.width * 2,
                                height: cvsSize.value.height * 2
                            };
                            imageCoverRect.value = drawing_helper_1.computeCoverCropRect(props.imageData, cvsSize.value);
                            _a = imageSource;
                            return [4, createImageBitmap(props.imageData)];
                        case 1:
                            _a.value = _b.sent();
                            return [4, updateImageCanvas()];
                        case 2:
                            _b.sent();
                            _b.label = 3;
                        case 3: return [2];
                    }
                });
            }); }));
            vue_3.watch(function () { return imageCoverRect.value; }, function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4, updateImageCanvas()];
                        case 1:
                            _a.sent();
                            return [2];
                    }
                });
            }); });
            vue_3.watch(function () { return props.zoomRatio; }, function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4, updateImageRectByZoom(props.zoomRatio)];
                        case 1:
                            _a.sent();
                            return [2];
                    }
                });
            }); });
        }
        function computedValidCanvasSize(boxSize, targetSize) {
            if (targetSize.width < boxSize.width && targetSize.height < boxSize.height) {
                return targetSize;
            }
            else {
                var aspectRatio = targetSize.width / targetSize.height;
                return computedSizeForAspectRatio(boxSize, aspectRatio);
            }
        }
        function computedSizeForAspectRatio(boxSize, aspectRatio) {
            if (aspectRatio >= 1) {
                return {
                    width: boxSize.width,
                    height: Math.floor(boxSize.width / aspectRatio)
                };
            }
            else {
                return {
                    width: Math.floor(boxSize.height * aspectRatio),
                    height: boxSize.height
                };
            }
        }
        function updateImageCanvas() {
            return __awaiter(this, void 0, void 0, function () {
                var ctx, _a, cvsWidth, cvsHeight, zoomRatio, image, coverRect, cropRect, translateX, translateY;
                return __generator(this, function (_b) {
                    ctx = cvsCtx.value;
                    _a = cvsSize.value, cvsWidth = _a.width, cvsHeight = _a.height;
                    zoomRatio = props.zoomRatio;
                    image = imageSource.value;
                    coverRect = imageCoverRect.value;
                    if (!ctx || !coverRect || !image) {
                        return [2];
                    }
                    cropRect = {
                        x: coverRect.tx / coverRect.sx,
                        y: coverRect.ty / coverRect.sy,
                        width: cvsWidth / coverRect.sx,
                        height: cvsHeight / coverRect.sy
                    };
                    translateX = -1 * (zoomRatio - 1) * cvsWidth / 2;
                    translateY = -1 * (zoomRatio - 1) * cvsHeight / 2;
                    dom_helper_1.clearCanvas(ctx);
                    ctx.save();
                    ctx.translate(translateX, translateY);
                    ctx.scale(zoomRatio, zoomRatio);
                    ctx.drawImage(image, cropRect.x, cropRect.y, cropRect.width, cropRect.height, 0, 0, cvsWidth, cvsHeight);
                    ctx.restore();
                    return [2];
                });
            });
        }
        function updateImageRectByZoom(zoomRatio) {
            var image = imageSource.value;
            var rect = imageCoverRect.value;
            var scaledImageSize = {
                width: image.width * rect.sx,
                height: image.height * rect.sy
            };
            var addtionalX = (zoomRatio - 1) * cvsSize.value.width / 2 / zoomRatio;
            var addtionalY = (zoomRatio - 1) * cvsSize.value.height / 2 / zoomRatio;
            rect.tx = Math.max(-addtionalX, Math.min(rect.tx, scaledImageSize.width - cvsSize.value.width + addtionalX));
            rect.ty = Math.max(-addtionalY, Math.min(rect.ty, scaledImageSize.height - cvsSize.value.height + addtionalY));
            imageCoverRect.value = __assign({}, rect);
        }
        function updateImageRectByDrag(distanceX, distanceY) {
            var image = imageSource.value;
            var rect = imageCoverRect.value;
            var scaledImageSize = {
                width: image.width * rect.sx,
                height: image.height * rect.sy
            };
            rect.tx -= distanceX / props.zoomRatio;
            rect.ty -= distanceY / props.zoomRatio;
            var addtionalX = (props.zoomRatio - 1) * cvsSize.value.width / 2 / props.zoomRatio;
            var addtionalY = (props.zoomRatio - 1) * cvsSize.value.height / 2 / props.zoomRatio;
            rect.tx = Math.max(-addtionalX, Math.min(rect.tx, scaledImageSize.width - cvsSize.value.width + addtionalX));
            rect.ty = Math.max(-addtionalY, Math.min(rect.ty, scaledImageSize.height - cvsSize.value.height + addtionalY));
            imageCoverRect.value = __assign({}, rect);
        }
        function updatePrevDownPoint(x, y) {
            prevDownPoint.value = { x: x, y: y };
        }
        var handleMouseDown = function (e) {
            isDragging.value = true;
            updatePrevDownPoint(e.offsetX, e.offsetY);
        };
        var handleMouseMove = function (e) {
            if (!isDragging.value) {
                return;
            }
            var distanceX = e.offsetX - prevDownPoint.value.x;
            var distanceY = e.offsetY - prevDownPoint.value.y;
            updateImageRectByDrag(distanceX, distanceY);
            updatePrevDownPoint(e.offsetX, e.offsetY);
        };
        var handleMouseUp = function (e) {
            isDragging.value = false;
            prevDownPoint.value = null;
        };
        var handleMouseOut = function (e) {
            isDragging.value = false;
            prevDownPoint.value = null;
        };
        return function (_ctx, _cache) {
            return (vue_2.openBlock(), vue_2.createElementBlock("div", {
                class: "image-board-box",
                ref_key: "boxEle",
                ref: boxEle
            }, [
                vue_2.createElementVNode("canvas", {
                    ref_key: "cvsEle",
                    ref: cvsEle,
                    class: "cursor-pointer",
                    width: cvsSize.value.width,
                    height: cvsSize.value.height,
                    style: vue_2.normalizeStyle({
                        width: cvsSize.value.width / 2 + 'px',
                        height: cvsSize.value.height / 2 + 'px'
                    }),
                    onMousemove: handleMouseMove,
                    onMousedown: handleMouseDown,
                    onMouseup: handleMouseUp,
                    onMouseout: handleMouseOut
                }, null, 44, _hoisted_1)
            ], 512));
        };
    }
});
