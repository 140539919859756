<template>
  <div class="image-resizer-wrap">
    <div class="header flex justify-start items-center">
      <back-btn @back="handleBack" />
    </div>
    <!-- 模型加载loading -->
    <div class="back-ground-remover-wrap" :style="{ paddingTop: minClient ? '24px' : '49px' }">
      <!-- 图片展示区域 -->
      <div class="backGroundRemover-container" v-if="imageHandleComplete && modelInProgress === 'done'">
        <div class="container" style="width: 540px; height: 540px">
          <div @mouseenter="handleDownloadBoxMouseEnter" @mouseleave="handleDownloadBoxMouseLeave" class="position-box">
            <drag-crop-board ref="cropBoardRef" :imageData="originImageData" :zoomRatio="scaleValue"
              :limitSize="limitSize" :targetSize="imageSize" />
            <div class="buttons-container" v-if="showDownloadBtns">
              <a-button type="primary" @click="handleMattingDownload">Download</a-button>
            </div>
            <div class="common-rate-abs">
              <common-rate @changeRate="handleMattingChangeRate" />
            </div>
          </div>
        </div>
        <div class="operation-board">
          <div class="marginB36">
            <div class="operation-item display-flex">
              <h3 class="operation-item-title">Customize :</h3>
              <div class="operation-input">
                <div>
                  <a-input-number v-model:value="imageSize.width" class="input-number ant-input-number-opacity"
                    @change="handleChangeImageSizeWidth" />
                  px
                </div>
                <a-tooltip placement="bottom" overlayClassName="resize-tooltip">
                  <template #title>
                    {{
                      isRatioLock ? "Unlock aspect ratio" : "Lock aspect ratio"
                    }}</template>
                  <div class="lock-box" @click="handleRatioLock">
                    <img :src="iconLock" class="icon-link-image" v-if="isRatioLock" />
                    <img :src="iconLockOpen" class="icon-link-image" v-else />
                  </div>
                </a-tooltip>

                <div>
                  <a-input-number v-model:value="imageSize.height" class="input-number ant-input-number-opacity"
                    @change="handleChangeImageSizeHeight" />
                  px
                </div>
              </div>
            </div>
            <p class="error-tip" v-if="showError">{{ showError }}</p>
          </div>
          <div class="operation-item">
            <h3 class="operation-item-title">Or resize to fit :</h3>
            <div class="operation-option-box">
              <div v-for="item in operationList" :key="item.id" class="operation-option-item"
                @click="handleSelectRatio(item)" :style="{
                  border:
                    item.id === currentRatioItem?.id ? '2px solid #875EFF' : '',
                }">
                <div class="operation-option-item-display">
                  <div class="item-box" :style="{
                    width: item.width + 'px',
                    height: item.height + 'px',
                  }">
                    {{ item.ratio }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="operation-item">
            <h3 class="operation-item-title" :style="{
              fontSize: '18px',
              marginTop: '12px',
              marginBottom: '18px',
            }">
              Image scaling
            </h3>
            <div class="operation-option-box">
              <div class="slider-box">
                <a-slider :value="scaleValue" :max="10" :min="1" @change="handleAfterChange" step="0.05"
                  :tooltipVisible="false" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import loadImage from "blueimp-load-image";
import { ref, defineProps, computed, watchEffect, onMounted, defineEmits } from "vue";
import dataUtils from "@/utils/util.js";
import DragCropBoard from "@/components/drag-crop-board";
import CommonRate from "@/components/common-rate.vue";
import iconCropImage from "../../assets/example-images/resize_src.png";
import { eventTracking } from "@/utils/eventTracking.js";
import iconLock from "@/assets/icon_link.svg";
import BackBtn from "@/components/boolv-ui/back-btn";
import iconLockOpen from "@/assets/icon_lock_open.svg";
import { storeToRefs } from 'pinia'
import { useSideStore, useViewStore } from '@/store/index.js'
const sideStore = useSideStore()
const viewStore = useViewStore()
const { minClient } = storeToRefs(sideStore)
const emits = defineEmits(["back"]);
var md5 = require("md5");
const props = defineProps({
  imageObj: Object,
});

onMounted(() => {
  // viewStore.setMinViewWidth('1400px')
  clearCanvas();
  loadImageToCanvas(props.imageObj.url);
})

const operationList = [
  {
    id: 1,
    ratio: "16:9",
    width: 102,
    height: 56,
    ratioNumber: (16 / 9).toFixed(10),
  },
  {
    id: 2,
    ratio: "9:16",
    width: 56,
    height: 102,
    ratioNumber: (9 / 16).toFixed(10),
  },
  {
    id: 3,
    ratio: "4:3",
    width: 102,
    height: 78,
    ratioNumber: (4 / 3).toFixed(10),
  },
  {
    id: 4,
    ratio: "1:1",
    width: 102,
    height: 102,
    ratioNumber: (1 / 1).toFixed(10),
  },
  {
    id: 5,
    ratio: "3:2",
    width: 102,
    height: 67,
    ratioNumber: (3 / 2).toFixed(10),
  },
  {
    id: 6,
    ratio: "2:3",
    width: 68,
    height: 102,
    ratioNumber: (2 / 3).toFixed(10),
  },
];
const limitSize = {
  min: 50,
  max: 6000,
};
const cropBoardRef = ref(null);
const uploadPlaceholder = ref("");
const inferenceTime = ref(null);
const sessionRunning = ref(false);
const imageURLInput = ref("");
const modelInProgress = ref("");
const imageHandleComplete = ref(false);
const imageLoadingError = ref(false);
const currentFileList = ref([]);
const showAddDesignResult = ref(false);
const changeImage = ref(false);
const rawImage = ref(null);
const clientHeight = ref(0);
const clearRate = ref(false);
const currentRatioItem = ref(null);
const originImageData = ref(null);
const originRatioItem = ref(null);
const imageSize = ref({});
const isRatioLock = ref(true);
const isCropImage = ref(false);
const showError = ref("");
const scaleValue = ref(1);
const showDownloadBtns = ref(false);
const canAbleMoveDistance = ref(null);
const canAbleMoveDis = ref(null);
const currentAspectRatio = ref(null);
const targetSize = ref(null);

const getClientHeight = () => {
  const height = document.documentElement.clientHeight;
  clientHeight.value = height;
};
const getPadding = computed(() => {
  if (imageHandleComplete.value && clientHeight.value <= 789) {
    return "18px";
  } else if (
    imageHandleComplete.value &&
    clientHeight.value > 789 &&
    clientHeight.value <= 900
  ) {
    return "48px";
  } else if (imageHandleComplete.value && clientHeight.value > 900) {
    return "80px";
  } else {
    return "180px";
  }
});

const getMarginBottom = computed(() => {
  if (imageHandleComplete.value && clientHeight.value <= 789) {
    return "30px";
  } else {
    return "48px";
  }
});

const getMarginTop = computed(() => {
  if (imageHandleComplete.value && clientHeight.value <= 789) {
    return "30px";
  } else if (imageHandleComplete.value && clientHeight.value > 789) {
    return "82px";
  } else {
    return "-200px";
  }
});

watchEffect(() => {
  if (imageSize.value) {
    targetSize.value = {
      width: imageSize.value.width,
      height: imageSize.value.height,
    };
  }
});
const handleMattingChangeRate = (item) => {
  const emoji = ['star_struck', 'face_blowing_a_kiss', 'thinking_face', 'face_vomiting'];
  eventTracking('booltool_page_feedback', { tool_name: 'resizer', emoji_str: emoji[item.id - 1] });
}
// 监听窗口变化，调整间距
window.onresize = () => {
  getClientHeight();
};
// 文件上传
const changFileList = (fileList) => {
  changeImage.value = true;
  clearRate.value = true;
  if (!fileList || !fileList.length) {
    return;
  }
  clearCanvas();
  modelInProgress.value = "start";
  currentFileList.value = fileList;
  const file = fileList[0].originFileObj;
  if (imageHandleComplete.value) {
    imageHandleComplete.value = false;
  }
  const url = URL.createObjectURL(file);
  loadImageToCanvas(url);
};
// 点击选择图片
const drow = (url) => {
  clearCanvas();
  loadImageToCanvas(url);
};

// 上传之后canvas 绘制图片
const loadImageToCanvas = (url) => {
  if (!url) {
    rawImage.value = null;
    return;
  }
  modelInProgress.value = "progressing";
  loadImage(
    url,
    async (img) => {
      if (img.type === "error") {
        imageLoadingError.value = true;
        modelInProgress.value = "done";
      } else {
        imageSize.value = {
          width: img.width,
          height: img.height,
        };

        // 如果比例列表里面有符合的比例，高亮
        const item = operationList.find((item) => {
          const ratio = Number((img.width / img.height).toFixed(3));
          if (ratio - 0.005 <= item.ratioNumber <= ratio + 0.005) {
            return item;
          }
          // return item.ratioNumber === (img.width / img.height).toFixed(3)
        });

        if (item && isRatioLock.value) {
          currentRatioItem.value = item;
          isCropImage.value = true;
        } else {
          // 保持原始比例
          originRatioItem.value = {
            id: 999,
            ratio: `${img.width}:${img.height}`,
            width: img.width,
            height: img.height,
            ratioNumber: (img.width / img.height).toFixed(10),
          };
        }
        imageLoadingError.value = false;
        sessionRunning.value = true;
        inferenceTime.value = 0;
        const tempCtx = dataUtils.createCanvasCtx(img);
        tempCtx.drawImage(img, 0, 0);
        const imageData = tempCtx.getImageData(0, 0, img.width, img.height);
        rawImage.value = imageData;
        originImageData.value = imageData;
        getClientHeight();
        modelInProgress.value = "done";
        uploadPlaceholder.value = "Change an image here";
        imageHandleComplete.value = true;
      }
    },
    {
      cover: true,
      crop: true,
      canvas: true,
      crossOrigin: "Anonymous",
    }
  );
};

// /** 获取指定链接下的位图图像 */
// async function getLoadedImage (picFile) {
//   const img = new Image();
//   img.crossOrigin = 'anonymous';
//   img.src = (isString(picFile) ? picFile : URL.createObjectURL(picFile))
//   await new Promise((resolve) => {
//     img.onload = () => resolve();
//   });
//   const canvas = dataUtils.createCanvasCtx(img);
//   canvas.drawImage(img, 0, 0);
//   return canvas.getImageData(0, 0, img.width, img.height);
// }

// 清除画布
const clearCanvas = () => {
  inferenceTime.value = 0;
  imageURLInput.value = "";
  imageHandleComplete.value = false;
  imageLoadingError.value = false;
  showAddDesignResult.value = false;
  currentFileList.value = [];
  rawImage.value = null;
};

const handleSelectRatio = (item) => {
  currentRatioItem.value = item;
  canAbleMoveDis.value = null;
  currentAspectRatio.value = item.ratioNumber;
  // 计算裁剪尺寸
  computedCropImage(originImageData.value, item);
};

const computedCropImage = async (originImageData, currentRatioItem) => {
  const resizeImageResult = dataUtils.resizeImage(
    originImageData,
    currentRatioItem.ratio
  );
  const { width, height, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight } =
    resizeImageResult;
  canAbleMoveDistance.value = {
    sx,
    sy,
  };
  const constCtx = dataUtils.createCanvasCtx({ width, height });
  const srcBitmap = await createImageBitmap(originImageData);
  // 绘制图像的中心点
  const rectCenterPoint = {
    x: 0 + width / 2,
    y: 0 + height / 2,
  };
  constCtx.translate(
    rectCenterPoint.x * (1 - scaleValue.value),
    rectCenterPoint.y * (1 - scaleValue.value)
  );
  constCtx.scale(scaleValue.value, scaleValue.value);
  constCtx.drawImage(
    srcBitmap,
    sx,
    sy,
    sWidth,
    sHeight,
    dx,
    dy,
    dWidth,
    dHeight
  );
  const cropImageData = constCtx.getImageData(0, 0, width, height);
  rawImage.value = cropImageData;
  isCropImage.value = true;
  imageSize.value = {
    width,
    height,
  };
};

const sizeLimits = (value) => {
  if (value > 6000) {
    showError.value = "Maximum size is 6000 pixels.";
    return;
  } else if (value < 50) {
    showError.value = "Minimum size is 50 pixels.";
    return;
  } else {
    showError.value = "";
  }
};

const handleChangeImageSizeWidth = async (value) => {
  canAbleMoveDis.value = null;
  sizeLimits(value);

  let ratioArr

  if(currentRatioItem.value) {
    ratioArr = currentRatioItem.value?.ratio.split(':')
  }else {
    ratioArr = originRatioItem.value?.ratio.split(':')
  }
  
  if (isRatioLock.value && !currentRatioItem.value) {
    imageSize.value = {
      width: value,
      height: (value * ratioArr[1] / ratioArr[0]).toFixed(0),
    };
  }
  if (isRatioLock.value && currentRatioItem.value) {
    imageSize.value = {
      width: value,
      height: (value * ratioArr[1] / ratioArr[0]).toFixed(0),
    };
  }
  // drawImage()
};

const handleChangeImageSizeHeight = async (value) => {
  canAbleMoveDis.value = null;
  sizeLimits(value);

  let ratioArr

  if(currentRatioItem.value) {
    ratioArr = currentRatioItem.value?.ratio.split(':')
  }else {
    ratioArr = originRatioItem.value?.ratio.split(':')
  }
  
  
  if (isRatioLock.value && !currentRatioItem.value) {
    imageSize.value = {
      width: (value * ratioArr[0] / ratioArr[1]).toFixed(0),
      height: value,
    };
  }

  if (isRatioLock.value && currentRatioItem.value) {
    imageSize.value = {
      width: (value * ratioArr[0] / ratioArr[1]).toFixed(0),
      height: value,
    };
  }
  // drawImage()
};

const handleRatioLock = () => {
  isRatioLock.value = !isRatioLock.value;
  if (!isRatioLock.value) {
    currentRatioItem.value = null;
  } else {
    const item = operationList.find((item) => {
      const ratio = Number(
        (imageSize.value.width / imageSize.value.height).toFixed(3)
      );
      if (
        ratio - 0.005 < item.ratioNumber &&
        item.ratioNumber < ratio + 0.005
      ) {
        return item;
      }
    });

    if (item) {
      currentRatioItem.value = item;
    }
  }
};

const drawImage = async (moveX = 0, moveY = 0) => {
  if (!imageSize.value.width || !imageSize.value.height) {
    return;
  }
  let resultData = {};
  resultData = dataUtils.resizeImageWidthOrHeight(
    originImageData.value,
    imageSize.value
  );
  const { width, height, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight } =
    resultData;
  console.log("width", width, height);
  if (!width || !height) {
    return;
  }
  const constCtx = dataUtils.createCanvasCtx({ width, height });
  const srcBitmap = await createImageBitmap(originImageData.value);
  // 绘制图像的中心点
  const rectCenterPoint = {
    x: 0 + width / 2,
    y: 0 + height / 2,
  };
  constCtx.translate(
    rectCenterPoint.x * (1 - scaleValue.value),
    rectCenterPoint.y * (1 - scaleValue.value)
  );
  constCtx.scale(scaleValue.value, scaleValue.value);

  constCtx.drawImage(
    srcBitmap,
    sx - moveX,
    sy - moveY,
    sWidth,
    sHeight,
    dx,
    dy,
    dWidth,
    dHeight
  );

  canAbleMoveDistance.value = {
    sx: sx - moveX,
    sy: sy - moveY,
  };
  const cropImageData = constCtx.getImageData(0, 0, width, height);
  rawImage.value = cropImageData;
  isCropImage.value = true;
};
const handleAfterChange = (value) => {
  scaleValue.value = value;
  drawImage();
};

// 按钮的显示隐藏
const handleDownloadBoxMouseEnter = () => {
  showDownloadBtns.value = true;
};

const handleDownloadBoxMouseLeave = () => {
  showDownloadBtns.value = false;
};

// 下载
const handleMattingDownload = () => {
  // 数据上报
  eventTracking("booltool_page_download", { tool_name: "resizer", is_batch: false, is_success: true })
  const cropImageUrl = cropBoardRef.value.generateCropImageUrl();
  dataUtils.downloadWithUrl(cropImageUrl, `image-resize-${md5(Date.now())}`);
};
const handleBack = () => {
  emits("back");
};
</script>

<style lang="less" scoped>
.image-resizer-wrap {
  min-width: 1400px;
}

.header {
  width: 100%;
  padding-left: 36px;
  padding-top: 25px;
}

.back-ground-remover-wrap {
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.backGroundRemover-container {
  display: flex;
  justify-content: center;
}

.operation-board {
  margin-left: 68px;
  width: 480px;

  .operation-item-title {
    font-family: 'Inter', Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
  }

  .operation-item {
    h3 {
      margin-bottom: 0;
      margin-right: 64px;
    }
  }

  .operation-item.display-flex {
    display: flex;
    align-items: center;
  }

  .marginB36 {
    margin-bottom: 36px;
  }

  .error-tip {
    color: #969696;
    text-align: left;
    margin-bottom: 0;
    margin-top: 10px;
  }

  .icon-link-image {
    // margin: 0 14.19px;
  }

  .lock-box {
    margin: 0 14.19px;

    border-radius: 4px;
    width: 26px;
    height: 26px;
    cursor: pointer;

    &:hover {
      background: #ededed;
    }
  }

  .operation-input {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input-number {
    width: 74px;
    height: 32px;
    border-radius: 4px;
    margin-right: 6px;

    :global(.ant-input-number-handler-wrap) {
      display: none;
    }
  }

  .operation-option-box {
    width: 100%;
    height: 100%;
    margin-top: 24px;

    .slider-box {
      width: 270px;
    }

    .ant-slider {
      margin: 0;
    }

    :global(.ant-slider-rail) {
      background-color: #d9d9d9;
      border-radius: 20px;
    }

    :global(.ant-slider-track) {
      background-color: #5a5a5a !important;
      border-radius: 20px;

      &:hover {
        background-color: #5a5a5a;
      }
    }

    :global(.ant-slider-handle) {
      width: 18px;
      height: 18px;
      background-color: #575757;
      border: solid 2px #575757 !important;
      margin-top: -8px;
      box-shadow: none;

      transition: none;

      &:focus {
        border-color: #575757;
        background-color: #575757;
        box-shadow: none;
      }
    }

    :global(.ant-slider-handle.ant-slider-handle) {
      border-color: #575757;
      background-color: #fff;
      box-shadow: none;
    }

    :global(.ant-slider-step) {
      border-color: red;
    }

    :global(.ant-tooltip .ant-tooltip-inner) {
      background: #1f2329;
      border-radius: 4px;
    }

    :global(.ant-tooltip .ant-tooltip-arrow) {}

    :global(.ant-tooltip-arrow-content::before) {
      clip-path: none;
    }

    :global(.ant-tooltip-arrow) {
      width: 12px;
      height: 12px;
    }

    :global(.ant-slider-handle.ant-tooltip-open) {
      background-color: #5a5a5a;
      border-color: #5a5a5a;

      &:focus {
        border-color: #5a5a5a;
        background-color: #fff;
        box-shadow: none;
      }
    }

    :global(.ant-slider-handle.ant-slider-handle-click-focused) {
      background-color: #fff;

      &:focus {
        border-color: #5a5a5a;
        background-color: #fff;
        box-shadow: none;
      }
    }
  }

  .operation-option-item {
    width: 120px;
    height: 120px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    display: inline-block;
    margin-right: 39px;
    margin-bottom: 24px;

    .operation-option-item-display {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .item-box {
      background: #d9d9d9;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.tool-title {
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #000000;
}

.common-upload-box {
  width: 604px;
  height: 92px;
  border: 2px dashed #878787;
  border-radius: 6px;
}

.backend-selector {
  width: 200px;
}

.upload-box {
  width: 245px;
  border-radius: 6px;
}

.position-box {
  position: relative;
  width: 100%;
  height: 100%;

  .buttons-container {
    width: 540px;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 80px 14px 80px;
    display: flex;
    justify-content: space-between;
    z-index: 99;
    font-family: 'Inter', Arial;
    display: flex;
    justify-content: center;
    // animation: fadenum 0.5s 1;

    .ant-btn {
      width: 120px;
      height: 36px;
      border-radius: 200px;
      color: #fff;
      border: none;
    }

    .ant-btn-default {
      background: rgba(0, 0, 0, 0.8);
      opacity: 0.8;
    }

    .ant-btn-primary {
      opacity: 1;
      background: linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%);

      &:hover {
        background: linear-gradient(289deg, #7646ff 18.5%, #9c7aff 99.95%);
      }
    }
  }

  @keyframes fadenum {
    0% {
      opacity: 0;
      transform: scaleX(0.5);
    }

    100% {
      opacity: 1;
      transform: scaleX(1);
    }
  }
}

:global(.resize-tooltip .ant-tooltip-content) {
  width: fit-content;
}

:global(.resize-tooltip .ant-tooltip-inner) {
  width: fit-content;
}
</style>
